<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">ADMINISTRACION DE MISTY ORACLE</h1>
  </div>
</template>

<script>
export default {
  name: "HomeAdmin",
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
}
.container-table {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
}
.container-paginator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
}
</style>
